import SunTemplate from '@/modules/questionnaire/components/steps/common/oxidative-stress/sun/SunTemplate';

export default {
  title: 'Steps/OxidativeStress/Sun/SunTemplate'
};

const createStory = props => () => ({
  components: { SunTemplate },
  storyProps: props,

  wrapStyles: {
    margin: '20px auto',
    width: '360px',
    maxWidth: '100%'
  },
  template: `<div :style='$options.wrapStyles'>
    <sun-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  cityOfResidenceLabel: '',
  countryOfResidenceLabel: ''
});

export const SelectedCountryAndCityWithContrast = createStory({
  cityOfResidence: 'baku',
  countryOfResidence: 'az',
  temperatureContrast: true,
  plannedHighUVExposure: 'yes'
});
