<template>
  <div class="single-answer-select">
    <div v-if="title" class="single-answer-select__title">
      {{ $t(title) }}
    </div>
    <div v-if="hint" class="single-answer-select__hint">
      {{ $t(hint) }}
    </div>

    <u-dropdown
      class="single-answer-select__dropdown q-mt16"
      :value="value"
      :options="options"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="onChange"
    >
      <template #value>{{ value }}</template>
      <template #placeholder> {{ placeholder }}</template>
      <template #option="{ option }">
        {{ option.label }}
      </template>
    </u-dropdown>
  </div>
</template>

<script>
import { UDropdown } from 'universkin-shared';

export default {
  name: 'SingleAnswerSelect',
  components: { UDropdown },
  props: {
    value: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange({ value }) {
      this.$emit('input', value);
    }
  }
};
</script>
<style lang="scss" scoped>
.single-answer-select {
  &__title {
    font-family: var(--u-font-family-Helvetica);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
  }

  &__dropdown {
    width: 100%;
  }
}
</style>
