<template>
  <div class="sun q-w360 q-fit">
    <div class="sun__title">{{ $t('label.currentlyLive') }}</div>
    <div class="sun__caption q-mt8">{{ $t('help.currentlyLive') }}</div>

    <question-with-error-wrap
      class="q-mt24 q-mt32-md"
      :error="getFieldsError(['countryOfResidence'])"
    >
      <single-answer-select
        title="label.country"
        :value="countryOfResidenceLabel"
        :placeholder="$t('label.country')"
        :options="sortedCountries"
        @input="onFieldChange('countryOfResidence', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap class="q-mt24 q-mt32-md" :error="getFieldsError(['cityOfResidence'])">
      <single-answer-select
        title="help.city"
        :placeholder="$t('label.city')"
        :value="cityOfResidenceLabel"
        :options="sortedCities"
        :disabled="!countryOfResidence"
        @input="onFieldChange('cityOfResidence', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="temperatureContrast"
        class="q-mt32 q-mt40-md"
        :error="getFieldsError(['plannedHighUVExposure'])"
      >
        <single-answer-question
          id="uv-exposure"
          :title="location"
          :value="plannedHighUVExposure"
          :options="$options.yesNoOptions"
          dysfunction="oxidative-stress"
          yes-no
          @input="onFieldChange('plannedHighUVExposure', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import { sortBy } from 'lodash';

import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import SingleAnswerSelect from '@/modules/questionnaire/new-design-components/questions/SingleAnswerSelect';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';
import { displayNameMixin } from '@/mixins/displayNameMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';
import { COUNTRY_CODES, COUNTRY_ISO } from '@/api/places/COUNTRIES';

import * as Places from '@/api/places';

export default {
  name: 'SunTemplate',
  components: {
    FadeInTransitionWrap,
    QuestionWithErrorWrap,
    SingleAnswerQuestion,
    SingleAnswerSelect
  },
  mixins: [stepTemplateMixin, displayNameMixin],
  yesNoOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    countryOfResidence: {
      type: String,
      default: ''
    },
    cityOfResidence: {
      type: String,
      default: ''
    },
    plannedHighUVExposure: {
      type: String,
      default: ''
    },
    temperatureContrast: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    location() {
      const city = this.cityOfResidence
        ? this.$t(`city.${this.countryOfResidence}.${this.cityOfResidence}`)
        : '*';

      return this.$t('label.plannedHighUVExposure', [city]);
    },
    countryOfResidenceLabel() {
      if (this.countryOfResidence) {
        const country = this.sortedCountries.find(({ value }) => value === this.countryOfResidence);

        return country ? country.label : '';
      }

      return this.getCountryDisplayName(this.countryOfResidence);
    },
    cityOfResidenceLabel() {
      if (this.countryOfResidence && this.cityOfResidence) {
        const { label } = this.sortedCities.find(({ value }) => value === this.cityOfResidence);

        return label;
      }

      return this.cityOfResidence;
    },
    sortedCountries() {
      const countries = COUNTRY_CODES.map(code => ({
        label: this.getCountryDisplayName(code),
        value: code
      }));

      return sortBy(countries, country => country.label);
    },
    sortedCities() {
      const country = this.countryOfResidence && this.countryOfResidence.toLowerCase();

      if (country && country !== COUNTRY_ISO.HONG_KONG.toLowerCase()) {
        return Places.getSortedCities(this.countryOfResidence).map(([code, name]) => ({
          label: this.$t(name),
          value: code
        }));
      }

      return [];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';

.sun {
  padding-bottom: 170px;
  font-family: var(--u-font-family-Helvetica);

  &__title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  &__caption {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: $color-grey500;
    text-align: center;
  }
}
</style>
